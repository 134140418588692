import { ISavingsData, ISavingsParams } from '../interfaces/savings-interfaces';
import { CC_API_ENDPOINTS } from '../constants/api';

/**
 * Common util to generate url query params
 * @param params - query params object
 */
export const createQueryString = (
  params: Record<string, string | number>
): string => {
  const searchParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    searchParams.append(key, String(value));
  });

  return searchParams.toString();
};

/**
 * Common fetcher util
 * @param endpoint - endpoint url
 * @param params - endpoint query params
 */
async function fetchData<T>(
  endpoint: string,
  params: Record<string, string | number>
): Promise<T> {
  try {
    const response = await fetch(`${endpoint}?${createQueryString(params)}`);
    if (!response.ok) {
      throw new Error(`API error: ${response.statusText}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Fetch Error:', error);
    throw error;
  }
}

export const fetchSavings = async (params: ISavingsParams) => {
  return await fetchData<ISavingsData>(CC_API_ENDPOINTS.savingsInfo, params);
};
