import useSWR from 'swr';
import { IProperties } from '../components/FilterPanel/Savings/interface';
import { UTCFormat } from '../utils/dateFormat';
import { CC_API_ENDPOINTS } from '../constants/api';
import { createQueryString, fetchSavings } from '../utils/api-utils';

const useSavings = ({ date, operator = '', block = '' }: IProperties) => {
  const dateFormatParams = `yyyy-MM-dd'T'HH:mm:ss`;

  const formattedDateFrom = UTCFormat(date?.from, dateFormatParams);
  const formattedDateTo = UTCFormat(date?.to, dateFormatParams);

  const savingsParams = {
    operatorName: operator,
    subLocationId: block,
    dateFrom: formattedDateFrom,
    dateTo: formattedDateTo
  };

  const { data, error, isLoading } = useSWR(
    `${CC_API_ENDPOINTS.savingsInfo}?${createQueryString(savingsParams)}`,
    () => fetchSavings(savingsParams)
  );

  return {
    data,
    isLoading,
    error
  };
};

export default useSavings;
