import React from 'react';
import styles from './Text.module.scss';

/**
 * Text component needs to show some text with tag.
 *
 * @example
 * <Text tag='div' type='body' className='yourClassName' style={{ fontSize: '10px' }}>Your text</Text>
 */

type TextType =
  | 'label'
  | 'label-medium'
  | 'body'
  | 'bodyInput'
  | 'caption'
  | 'caption-medium'
  | 'button'
  | 'button-medium'
  | 'stat'
  | 'stat-medium'
  | 'stat-large';

interface ITextProps {
  children: React.ReactNode;
  tag?: keyof JSX.IntrinsicElements;
  type?: TextType;
  className?: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler;
}

const classByText: Record<TextType, string> = {
  label: styles.label,
  'label-medium': styles['label-medium'],
  body: styles.body,
  bodyInput: styles.bodyInput,
  caption: styles.caption,
  'caption-medium': styles['caption-medium'],
  button: styles.button,
  'button-medium': styles['button-medium'],
  stat: styles.start,
  'stat-medium': styles['stat-medium'],
  'stat-large': styles['stat-large']
};

const Text: React.FC<ITextProps> = ({
  children,
  className = '',
  type = 'label',
  style = {},
  tag: Tag = 'p',
  onClick
}) => {
  return (
    <Tag
      className={`${className} ${classByText[type]}`}
      style={style}
      onClick={onClick}
    >
      {children}
    </Tag>
  );
};

export default Text;
