export const CHART_FONT = 'Montserrat, sans-serif';
export const CHART_LEGEND_DIVISION = 5;

export const CHART_LEGEND_COLORS = {
  green: '#386900',
  purple: '#5E4DB2',
  orange: '#E56910'
};

export const WIDGET_DEFAULT_HEIGHT = 404;
