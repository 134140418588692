import React from 'react';
import {
  FormControl,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent
} from '@mui/material';
import { IProperties } from './Savings/interface';
import styles from './FilterPanel.module.scss';
import Text from '../typographies/Text/Text';

/**
 * FilterOption component needs to display different options for filtering.
 *
 * @example
 * <FilterOption
 *   iconPath="icon path"
 *   title="Title"
 *   propertyName='name'
 *   options={[]}
 *   properties={properties}
 *   setProperties={setProperties}
 * />
 */

interface IFilterOptionProps {
  iconPath: string;
  title: string;
  options: string[];
  propertyName: 'operator' | 'block';
  properties: IProperties;
  setProperties: React.Dispatch<React.SetStateAction<IProperties>>;
}

const FilterOption: React.FC<IFilterOptionProps> = ({
  iconPath,
  title,
  options,
  propertyName,
  properties,
  setProperties
}) => {
  const selectProperty = (event: SelectChangeEvent<string>) => {
    const {
      target: { value }
    } = event;

    setProperties((prev) => {
      return {
        ...prev,
        [propertyName]: value
      };
    });
  };

  const clearProperty = () => {
    setProperties((prev) => {
      return {
        ...prev,
        [propertyName]: ''
      };
    });
  };

  return (
    <div className={styles.filterOption}>
      <img src={iconPath} />
      <div>
        <Text type="label" className={styles.title}>
          {title}
        </Text>
        <FormControl fullWidth className={styles.formControl}>
          <Select
            fullWidth
            displayEmpty
            value={properties?.[propertyName]}
            onChange={selectProperty}
            input={<OutlinedInput />}
            className={styles.select}
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none' // Remove the border
              },
              '& .MuiSelect-select': {
                padding: '6px 8px'
              }
            }}
          >
            <MenuItem value="" disabled>
              Select property...
            </MenuItem>
            {options.map((prop) => (
              <MenuItem key={prop} value={prop}>
                {prop}
              </MenuItem>
            ))}
          </Select>
          {properties?.[propertyName] && (
            <IconButton onClick={clearProperty}>
              <img src="/images/close.svg" />
            </IconButton>
          )}
        </FormControl>
      </div>
    </div>
  );
};

export default FilterOption;
