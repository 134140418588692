import React from 'react';
import WidgetCard, { IWidgetCardProps } from '../../WidgetCard/WidgetCard';
import Text from '../../typographies/Text/Text';

import styles from './TotalNumberWidget.module.scss';
import Legend from '../../Legend';

interface Props extends Omit<IWidgetCardProps, 'children'> {
  data: {
    label: string;
    value: number;
  };
}

const TotalNumberWidget: React.FC<Props> = ({
  title,
  description,
  data,
  isLoading
}) => {
  return (
    <WidgetCard title={title} description={description} isLoading={isLoading}>
      <div className={styles.container}>
        <Legend title={data.label} />
        <Text type="stat-large">{data.value}</Text>
      </div>
    </WidgetCard>
  );
};

export default TotalNumberWidget;
