import React from 'react';
import { Radar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  ChartData,
  ChartOptions
} from 'chart.js';
import WidgetCard, { IWidgetCardProps } from '../../WidgetCard/WidgetCard';
import styles from './RadarWidget.module.scss';
import { CHART_FONT, WIDGET_DEFAULT_HEIGHT } from '../../../constants/charts';
import { customTicksPlugin } from './customTicksPlugin';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  customTicksPlugin
);

interface Props extends Omit<IWidgetCardProps, 'children'> {
  /**
   * Data for the radar chart.
   * Should follow the Chart.js data structure for radar charts.
   */
  data: ChartData<'radar'>;
  /**
   * Chart.js options for customizing the radar chart.
   */
  options?: ChartOptions<'radar'>;
  /**
   * Chart height.
   * @default 404px
   */
  height?: number;
}

const RadarWidget: React.FC<Props> = ({
  data,
  options,
  height = WIDGET_DEFAULT_HEIGHT,
  ...widgetProps
}) => {
  return (
    <WidgetCard {...widgetProps}>
      <div style={{ height }} className={styles.container}>
        <Radar
          data={data}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              r: {
                grid: {
                  color: '#E9E9E9'
                },
                angleLines: {
                  color: '#E9E9E9'
                },
                ticks: {
                  display: true,
                  stepSize: 200
                },
                pointLabels: {
                  padding: 16,
                  color: '#8c8c8c', // Color of labels (Block 1, Block 2, etc.)
                  font: {
                    family: CHART_FONT,
                    size: 11
                  }
                }
              }
            },
            plugins: {
              legend: {
                display: false // Disable legend
              },
              tooltip: {
                backgroundColor: '#ffffff', // Custom tooltip background
                borderColor: '#386900',
                borderWidth: 1,
                titleColor: '#000000',
                bodyColor: '#000000',
                callbacks: {
                  label: function (tooltipItem) {
                    const value = tooltipItem.raw;
                    return `${value} saved gallons`;
                  }
                }
              }
            },
            elements: {
              line: {
                borderWidth: 1, // Line thickness
                borderColor: '#386900', // Line color
                backgroundColor: 'rgba(56, 105, 0, 0.2)' // Fill color
              },
              point: {
                backgroundColor: '#386900', // Point color
                hoverBorderWidth: 8,
                hoverRadius: 12
              }
            },
            ...options
          }}
        />
      </div>
    </WidgetCard>
  );
};

export default RadarWidget;
