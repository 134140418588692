import React from 'react';
import { DateRange } from 'react-day-picker';
import styles from './FilterPanel.module.scss';
import { format } from '../../../utils';
import { Chip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

/**
 * FiltrationChip component needs to display and delete selected filter in chip.
 *
 * @example
 * <FiltrationChip
 *   filtration="applied filter"
 *   onDelete={() => {}}
 * />
 */

interface IFiltrationChipProps {
  filtration: string | DateRange;
  onDelete: () => void;
}

const FiltrationChip: React.FC<IFiltrationChipProps> = ({
  filtration,
  onDelete
}) => {
  const dateFormat = 'dd MMM, yyyy';
  const formattedSelectedDateFrom =
    typeof filtration !== 'string' && format(filtration.from, dateFormat);
  const formattedSelectedDateTo =
    typeof filtration !== 'string' && format(filtration.to, dateFormat);

  return (
    <Chip
      className={styles.filtrationContainer}
      onDelete={onDelete}
      deleteIcon={<CloseIcon />}
      label={
        typeof filtration === 'string'
          ? filtration
          : `${formattedSelectedDateFrom} - ${formattedSelectedDateTo}`
      }
    />
  );
};

export default FiltrationChip;
