import React, { useEffect, useRef, useState } from 'react';
import 'react-day-picker/dist/style.css';
import { DateRange, DayPicker, getDefaultClassNames } from 'react-day-picker';
import styles from './FilterPanel.module.scss';
import Text from '../typographies/Text/Text';
import { Box, ClickAwayListener, OutlinedInput } from '@mui/material';
import { IProperties } from './Savings/interface';
import { format } from '../../../utils';

interface IDateFilterOptionProps {
  properties: IProperties;
  setProperties: React.Dispatch<React.SetStateAction<IProperties>>;
}

const DateFilterOption: React.FC<IDateFilterOptionProps> = ({
  properties,
  setProperties
}) => {
  const dateFormat = 'dd MMM, yyyy';

  const inputRef = useRef<HTMLInputElement | null>(null);
  const defaultClassNames = getDefaultClassNames();

  const [open, setOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<DateRange | undefined>(
    properties.date
  );
  const [formattedDateFrom, setFormattedDateFrom] = useState<
    Date | string | undefined
  >(format(selected?.from, dateFormat));
  const [formattedDateTo, setFormattedDateTo] = useState<
    Date | string | undefined
  >(format(selected?.to, dateFormat));

  const formattedSelectedDateFrom = format(properties?.date?.from, dateFormat);
  const formattedSelectedDateTo = format(properties?.date?.to, dateFormat);

  useEffect(() => {
    setFormattedDateFrom(format(selected?.from, dateFormat));
    setFormattedDateTo(format(selected?.to, dateFormat));
  }, [selected]);

  const handleDateFromChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormattedDateFrom(event.target.value);
  };

  const handleDateToChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormattedDateTo(event.target.value);
  };

  const handleDateFromKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.key === 'Enter') {
      const inputValue = event.currentTarget.value;
      const parsedDate = new Date(inputValue);
      if (!isNaN(parsedDate.getTime())) {
        setSelected((prev) => {
          return {
            to: prev?.to,
            from: parsedDate
          };
        });
        setFormattedDateFrom(format(parsedDate, dateFormat));
      }
    }
  };

  const handleDateToKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.key === 'Enter') {
      const inputValue = event.currentTarget.value;
      const parsedDate = new Date(inputValue);
      if (!isNaN(parsedDate.getTime())) {
        setSelected((prev) => {
          return {
            from: prev?.from,
            to: parsedDate
          };
        });
        setFormattedDateTo(format(parsedDate, dateFormat));
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const handleApply = () => {
    setProperties((prev) => {
      return {
        ...prev,
        date: selected
      };
    });
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClose} mouseEvent="onMouseDown">
      <div className={styles.filterOption}>
        <img src="/images/calendar_month.svg" />
        <div>
          <Text type="label" className={styles.title}>
            Date
          </Text>
          <Box>
            <OutlinedInput
              ref={inputRef}
              className={styles.dateInput}
              value={
                properties.date
                  ? `${formattedSelectedDateFrom} - ${formattedSelectedDateTo}`
                  : 'Select property...'
              }
              onClick={handleClick}
              readOnly
              fullWidth
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none' // Remove the border
                },
                '& .MuiInputBase-input': {
                  padding: '6px 8px'
                }
              }}
            />
            <div
              className={open ? styles.dateRangeFilter : styles.filterClosed}
            >
              <div className={styles.dateInputs}>
                <div>
                  <Text type="label">Start</Text>
                  <OutlinedInput
                    ref={inputRef}
                    value={formattedDateFrom}
                    onKeyDown={handleDateFromKeyDown}
                    onChange={handleDateFromChange}
                    fullWidth
                    sx={{
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none' // Remove the border
                      }
                    }}
                  />
                </div>
                <div>
                  <Text type="label">End</Text>
                  <OutlinedInput
                    ref={inputRef}
                    value={formattedDateTo}
                    onKeyDown={handleDateToKeyDown}
                    onChange={handleDateToChange}
                    fullWidth
                    sx={{
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none' // Remove the border
                      }
                    }}
                  />
                </div>
              </div>
              <DayPicker
                mode="range"
                weekStartsOn={1}
                selected={selected}
                onSelect={setSelected}
                classNames={{
                  selected: `rdp-custom-selected`,
                  root: `${defaultClassNames.root} ${styles.root}`
                }}
              />
              <div className={styles.buttonGroup}>
                <Text type="button" tag="button" onClick={handleClose}>
                  Cancel
                </Text>
                <Text
                  type="button"
                  tag="button"
                  className={styles.apply}
                  onClick={handleApply}
                >
                  Apply
                </Text>
              </div>
            </div>
          </Box>
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default DateFilterOption;
