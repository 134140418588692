import React from 'react';
import clsx from 'clsx';
import { Skeleton } from '@mui/material';
import styles from './WidgetCard.module.scss';
import Text from '../typographies/Text/Text';
import Heading from '../typographies/Heading/Heading';

/**
 * WidgetCard component needs to reuse and show widget data.
 *
 * @example
 *  <WidgetCard title="Your title" description="Your descr">
 *    Some children data
 *  </WidgetCard>
 */

export interface IWidgetCardProps {
  children: React.ReactNode;
  title: string;
  description: string;
  className?: string;
  isLoading?: boolean;
}

const WidgetCard: React.FC<IWidgetCardProps> = ({
  children,
  title,
  description,
  className,
  isLoading = false
}) => {
  return isLoading ? (
    <Skeleton className={styles.widgetCard} variant="rounded" />
  ) : (
    <div className={clsx(styles.widgetCard, className)}>
      <div className={styles.header}>
        <Heading level={3} className={styles.title}>
          {title}
        </Heading>
        <Text type="label" className={styles.description}>
          {description}
        </Text>
      </div>
      {children}
    </div>
  );
};

export default WidgetCard;
