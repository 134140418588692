import React from 'react';
import WidgetGroup from '../WidgetGroup';
import BarChartWidget from '../widgets/BarChartWidget';

const Runs = () => {
  return (
    <WidgetGroup title="🚜 Runs">
      <BarChartWidget
        title="📊 Runs Info"
        description="Shows daily data for the current week, including runs, herbicide sprayed, and herbicide saved."
        data={{
          labels: [
            'Day 1',
            'Day 2',
            'Day 3',
            'Day 4',
            'Day 5',
            'Day 6',
            'Day 7'
          ],
          datasets: [
            {
              label: 'Runs', // First bar for each day
              data: [12, 19, 3, 5, 2, 3, 9],
              backgroundColor: '#386900'
            },
            {
              label: 'Herbicide sprayed', // Second bar for each day
              data: [8, 15, 5, 8, 6, 7, 4],
              backgroundColor: '#5E4DB2'
            },
            {
              label: 'Herbicide saved', // Third bar for each day
              data: [18, 9, 11, 6, 7, 10, 5],
              backgroundColor: '#E56910'
            }
          ]
        }}
      />
    </WidgetGroup>
  );
};

export default Runs;
