import React from 'react';
import WidgetGroup from '../WidgetGroup';
import LineChartWidget from '../widgets/LineChartWidget';
import RadarWidget from '../widgets/RadarWidget';
import styles from './HerbicideSavings.module.scss';

const HerbicideSavings = () => {
  return (
    <WidgetGroup title="🌱 Herbicide Savings">
      <div className={styles.row}>
        <RadarWidget
          title="📊 Sprays per Block"
          description="Shows the amount of sprays per block."
          data={{
            labels: [
              'Block 1',
              'Block 2',
              'Block 3',
              'Block 4',
              'Block 5',
              'Block 6'
            ],
            datasets: [
              {
                label: 'Gallons Saved',
                data: [1000, 800, 200, 600, 400, 100]
              }
            ]
          }}
        />
        <LineChartWidget
          data={{
            labels: [
              'Day 1',
              'Day 2',
              'Day 3',
              'Day 4',
              'Day 5',
              'Day 6',
              'Day 7'
            ],
            datasets: [
              {
                data: [1500, 2000, 4000, 1900, 6000, 2700, 3900]
              }
            ]
          }}
          title="📊 Herbicide Savings (All Blocks)"
          description="Herbicide saved trend (gal)"
        />
      </div>
    </WidgetGroup>
  );
};

export default HerbicideSavings;
