import { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import authService from '../../../api-authorization/AuthorizeService';

const UpdateDeviceDialog = ({
  client,
  tractor,
  device,
  unaffiliatedDevices,
  open,
  onClose,
  onSuccess
}) => {
  const [loading, setLoading] = useState(false);
  const { handleSubmit, register, watch } = useForm({ defaultValues: device });
  const serialNumber = watch('serialNumber', device.serialNumber);
  const cameraName = watch('cameraName', device.cameraName);
  const flowMeterFrequency = watch(
    'flowMeterFrequency',
    device.flowMeterFrequency
  );
  const deviceType = watch('deviceType', device.deviceType);

  const updateDevice = async (data) => {
    if (!serialNumber) {
      return toast.error('Please select the device serial number');
    }
    if (!cameraName) {
      return toast.error('Please add a camera name');
    }
    if (isNaN(flowMeterFrequency)) {
      return toast.error(
        'Please enter a valid number value for flow meter frequency'
      );
    }
    if (!deviceType) {
      return toast.error('Please select the device type');
    }
    const token = await authService.getAccessToken();
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/admin/devices/update`,
        {
          method: 'PUT',
          headers: !token
            ? {}
            : {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
              },
          body: JSON.stringify({
            clientId: client.id,
            tractorId: tractor.id,
            ...data,
            isActive: true
          })
        }
      );

      const responseText = await response.text();

      if (responseText.includes('duplicate key')) {
        return toast.error('Device with this serial number already exists!');
      }

      if (response.ok) {
        toast.success('Device updated successfully!');
        onSuccess();
      } else {
        toast.error(`${response.status} ${response.statusText}`);
      }
    } catch (err) {
      toast.error('Error: ' + err);
      console.log('err', err);
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(updateDevice)}>
        <DialogTitle>
          <EditIcon /> Edit Tractor
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <FormControl>
              <FormLabel>Unaffiliated Devices</FormLabel>
              <Select
                disabled
                value={serialNumber}
                {...register('serialNumber')}
              >
                <MenuItem value={serialNumber}>{serialNumber}</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Camera Description</FormLabel>
              <TextField required {...register('cameraName')} />
            </FormControl>
            <FormControl>
              <FormLabel>Flow Meter Frequency</FormLabel>
              <TextField
                required
                type="number"
                InputProps={{ inputProps: { step: 'any' } }}
                {...register('flowMeterFrequency')}
              />
            </FormControl>
            <Select value={deviceType} {...register('deviceType')}>
              <MenuItem value="Recorder">Recorder</MenuItem>
              <MenuItem value="ControlUnit">Control Unit</MenuItem>
            </Select>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <Button type="submit" variant="outlined" disabled={loading}>
            Save Changes
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateDeviceDialog;
