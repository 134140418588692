import React from 'react';
import WidgetGroup from '../WidgetGroup';
import BarChartWidget from '../widgets/BarChartWidget';

import styles from './AssetManagement.module.scss';
import { CHART_LEGEND_COLORS } from '../../constants/charts';

const AssetManagement: React.FC = () => {
  return (
    <WidgetGroup title="⚙️ Asset Management">
      <div className={styles.container}>
        <BarChartWidget
          className={styles.widgetItem}
          title="📊 Performance Breakdown"
          description="Shows daily performance breakdown for the last 7 days."
          data={{
            labels: [
              'Day 1',
              'Day 2',
              'Day 3',
              'Day 4',
              'Day 5',
              'Day 6',
              'Day 7'
            ],
            datasets: [
              {
                label: 'Duration', // First bar for each day
                data: [12, 19, 3, 5, 2, 3, 9],
                backgroundColor: CHART_LEGEND_COLORS.green
              },
              {
                label: 'Idle time', // Second bar for each day
                data: [8, 15, 5, 8, 6, 7, 4],
                backgroundColor: CHART_LEGEND_COLORS.purple
              },
              {
                label: 'Spraying time', // Third bar for each day
                data: [18, 9, 11, 6, 7, 10, 5],
                backgroundColor: CHART_LEGEND_COLORS.orange
              }
            ]
          }}
        />

        <BarChartWidget
          className={styles.widgetItem}
          title="📊 Savings per Sprayer"
          description="Shows savings per sprayer for the last 7 days."
          data={{
            labels: [
              'Vehicle 1',
              'Vehicle 2',
              'Vehicle 3',
              'Vehicle 4',
              'Vehicle 5',
              'Vehicle 6',
              'Vehicle 7'
            ],
            datasets: [
              {
                label: 'Runs', // First bar for each day
                data: [12, 19, 3, 5, 2, 3, 9],
                backgroundColor: CHART_LEGEND_COLORS.green
              },
              {
                label: 'Herbicide sprayed', // Second bar for each day
                data: [8, 15, 5, 8, 6, 7, 4],
                backgroundColor: CHART_LEGEND_COLORS.purple
              },
              {
                label: 'Herbicide saved', // Third bar for each day
                data: [18, 9, 11, 6, 7, 10, 5],
                backgroundColor: CHART_LEGEND_COLORS.orange
              }
            ]
          }}
        />
      </div>
    </WidgetGroup>
  );
};

export default AssetManagement;
