import React from 'react';
import styles from '../FilterPanel.module.scss';
import FilterOption from '../FilterOption';
import DateFilterOption from '../DateFilterOption';
import { IProperties } from './interface';

/**
 * Filter component needs to filter and export data.
 *
 * @example
 * <Filter
 *    open={isOpen}
 *    properties={properties}
 *    setProperties={setProperties}
 *  />
 */

interface IFilterProps {
  open: boolean;
  properties: IProperties;
  setProperties: React.Dispatch<React.SetStateAction<IProperties>>;
}

const SavingsFilter: React.FC<IFilterProps> = ({
  open,
  properties,
  setProperties
}) => {
  return (
    <div className={open ? styles.filter : styles.filterClosed}>
      <DateFilterOption properties={properties} setProperties={setProperties} />
      <FilterOption
        iconPath="/images/person.svg"
        title="Operator"
        propertyName="operator"
        options={[]} // TODO: add valid data
        properties={properties}
        setProperties={setProperties}
      />
      <FilterOption
        iconPath="/images/plant.svg"
        title="Block"
        propertyName="block"
        options={[]} // TODO: add valid data
        properties={properties}
        setProperties={setProperties}
      />
    </div>
  );
};

export default SavingsFilter;
