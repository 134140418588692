import React, { useState } from 'react';
import WidgetGroup from '../WidgetGroup';
import TotalNumberWidget from '../widgets/TotalNumberWidget';

import styles from './Savings.module.scss';
import SavingsFilterPanel from '../FilterPanel/Savings/SavingsFilterPanel';
import LineChartWidget from '../widgets/LineChartWidget';
import { IProperties } from '../FilterPanel/Savings/interface';
import useSavings from '../../hooks/useSavings';
import { format } from '../../../utils';
import { FIXED_PRECISION } from '../../constants/numbers';

interface IPesticideUsage {
  date: string;
  pesticideUsedAmountGallons: number;
}

interface IPesticideSavings {
  date: string;
  pesticideSavedAmountGallons: number;
}

const Savings: React.FC = () => {
  const [properties, setProperties] = useState<IProperties>({
    date: undefined,
    operator: '',
    block: ''
  });

  const { data, isLoading } = useSavings(properties);

  const getDate = (
    arr: IPesticideUsage[] | IPesticideSavings[] | undefined
  ) => {
    const dateFormatChart = 'dd MMM, yyyy';
    return arr?.map((el) => format(el.date, dateFormatChart));
  };

  const pesticideUsagePesticideUsedAmountGallons: number[] | undefined =
    data?.pesticideUsage.map(
      (el: IPesticideUsage) => el.pesticideUsedAmountGallons
    );

  const pesticideSavingsPesticideUsedAmountGallons: number[] | undefined =
    data?.pesticideSavings?.map(
      (el: IPesticideSavings) => el.pesticideSavedAmountGallons
    );

  return (
    <WidgetGroup title="💰 Savings">
      <SavingsFilterPanel
        properties={properties}
        setProperties={setProperties}
      />
      <section className={styles.row}>
        <TotalNumberWidget
          isLoading={isLoading}
          title="📊 Herbicide Savings"
          description="Displays the amount of herbicide saved in gallons."
          data={{
            value: parseFloat(
              data?.totalPesticideSavedAmountGallons?.toFixed(
                FIXED_PRECISION
              ) ?? ''
            ),
            label: 'Gallons'
          }}
        />
        <TotalNumberWidget
          isLoading={isLoading}
          title="📊 Sprayed area"
          description="Shows the total area sprayed in acres."
          data={{
            value: parseFloat(
              data?.totalSprayedAreaAcres?.toFixed(FIXED_PRECISION) ?? ''
            ),
            label: 'Acres'
          }}
        />
      </section>
      <div className={styles.row}>
        <LineChartWidget
          isLoading={isLoading}
          data={{
            labels: getDate(data?.pesticideUsage),
            datasets: [
              {
                data: pesticideUsagePesticideUsedAmountGallons || []
              }
            ]
          }}
          title="📊 Herbicide Usage"
          subtitle="gal"
          description="Displays the herbicide usage dynamics."
        />
        <LineChartWidget
          isLoading={isLoading}
          data={{
            labels: getDate(data?.pesticideSavings),
            datasets: [
              {
                data: pesticideSavingsPesticideUsedAmountGallons || []
              }
            ]
          }}
          title="📊 Herbicide Savings Dynamics"
          subtitle="gal"
          description="Shows the herbicide savings trend."
        />
      </div>
    </WidgetGroup>
  );
};

export default Savings;
