import { format as datefnsFormat } from 'date-fns';
import { enUS } from 'date-fns/locale';

const locales: { [key: string]: Locale } = { enUS };

declare global {
  interface Window {
    __localeId__: string;
  }
}

/**
 * Formats a given date to UTC and returns it in a specific format based on the locale.
 *
 * @param {Date | undefined} date - The date to be formatted. If the date is undefined, an empty string is returned.
 * @param {string} [formatStr='PP'] - The format string to specify how the date should be formatted (defaults to 'PP').
 * @returns {string} - A formatted string representing the date in UTC.
 *
 * @example
 * const formattedDate = UTCFormat(new Date(), 'dd MMM yyyy');
 */

export const UTCFormat = (
  date: Date | undefined,
  formatStr: string = 'PP'
): string => {
  if (!date) return '';

  const utcDate = new Date(date).toISOString();
  const utcTime = new Date(utcDate);

  return datefnsFormat(utcTime, formatStr, {
    locale: locales[window.__localeId__]
  });
};
